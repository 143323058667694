import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { Grid, FormControl, InputLabel, MenuItem, Select, makeStyles } from '@material-ui/core';

import SearchInput from './../../../../../core-components/SearchInput';
import AppConstants from '../../../../../core-components/AppConstants';
import {
  ADC_TYPE_USAGE_LOG,
  CHECK_TYPE_SHORT_VERSION,
  displayDateFormat,
  EVENT_LIST,
  EVENT_PREPAID
} from '../../../../../core-components/CommonConstants';
import { getUsersList } from '../../CreditCompany/Shared/commonUtils';
import UsageLogsTable from '../../CreditCompany/Shared/Table';
import {
  convertToIndianNumeration,
  getCreditInRupee,
  getExcessBridgeCreditValue
} from '../../../../../utils/Utlities';
import { debounce } from 'lodash';
import { capitalizeName } from '../../../frontend-common/utils/Utilities';

//this is for each row the heading creation  with whatever id for headcells
function createData(event, date, candidate, description, amount, available_balance, user, actions) {
  return { event, date, candidate, description, amount, available_balance, user, actions };
}

const headCells = [
  {
    id: 'date',
    label: 'TXN Date'
  },
  {
    id: 'event',
    label: 'TXN Type'
  },
  {
    id: 'description',
    label: 'Description'
  },
  {
    id: 'candidate',
    label: 'Candidate Name'
  },
  {
    id: 'user',
    label: 'User'
  },
  {
    id: 'amount',
    label: 'Amount',
    align: 'end'
  },
  {
    id: 'available_balance',
    label: 'Available Balance',
    align: 'end'
  },
  {
    id: 'actions',
    label: 'Additional Info/Actions',
    align: 'center'
  }
];

const useStyles = makeStyles((theme) => ({
  orangeColorText: {
    color: 'orange',
    paddingLeft: '10px'
  },
  redColorText: {
    color: 'red',
    paddingLeft: '10px'
  },
  excessBridgeCredit: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end'
  },
  filterWrapper: {
    paddingTop: '33px'
  },
  wrapper: {
    width: '100%'
  },
  dateFilterWrapper: {
    maxWidth: '250px',
    marginLeft: '11px',
    marginTop: '15px'
  },
  dateFilter: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    color: 'grey'
  },
  creditType: {
    minWidth: '110px'
  },
  userFilter: {
    minWidth: '70px'
  },
  transactionFilter: {
    width: '100%'
  },
  orangeColorText: {
    color: '#ffa500'
  },
  redColorText: {
    color: '#ff0000'
  },
  excessBridgeText: {
    textAlign: 'end'
  },
  invoiceUrl: {
    textDecoration: 'none',
    color: '#2755fe'
  },
  colorBlack: {
    textDecoration: 'none',
    color: '#333'
  },
  costContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  rupeeSymbol: {
    fontFamily: 'Roboto',
    paddingTop: '2px'
  },
  excessBridgeValueContainer: {
    paddingLeft: '10px',
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

export default function UsageLog(props) {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [dateRange, setDateRange] = useState([null, null]);
  const [DateFrom, setDateFrom] = useState('');
  const [DateTo, setDateTo] = useState('');
  const [event, setEvent] = useState('All');
  const [name, setName] = useState('');
  const [userName, setuserName] = useState('All');
  const [creditType, setCreditType] = useState('All');
  const [companyUserList, setCompanyUserList] = useState([]);

  const eventList = [...EVENT_PREPAID, ...EVENT_LIST];

  const { prepaidUsageLogs, companyCreditBalance } = props;

  useEffect(() => {
    getUsersList('USAGE_LOGS', props?.companyId, setCompanyUserList);
  }, []);

  // 1st call is in parrent component
  useEffect(() => {
    setLoading(true);
    setOffset(0);
    getUsageLogs({
      rowsPerPage,
      offset: 0,
      event: event,
      from_date: DateFrom,
      to_date: DateTo ? DateTo : null,
      candidate_name: name,
      user_name: '',
      credit_type: getCreditOrDebit(),
      user_id: getUserName()
    });
  }, [props.companyId, name, event, DateFrom, DateTo, userName, creditType]);

  useEffect(() => {
    if (prepaidUsageLogs) {
      let rowsData =
        prepaidUsageLogs && prepaidUsageLogs.count ? getRowData(prepaidUsageLogs.rows) : [];
      setRows(rowsData);
      setTotalCount(prepaidUsageLogs.count);
    }
  }, [prepaidUsageLogs]);

  const getCreditOrDebit = () => {
    switch (creditType) {
      case 'All':
        return null;
      case 'Credit':
        return 'CREDIT';
      case 'Debit':
        return 'DEBIT';
    }
  };

  const getUserName = () => {
    if (userName === 'All') {
      return '';
    }

    return userName;
  };

  const handleCreditDebitFilter = (e) => {
    const value = e.target.value;
    setCreditType(value);
  };

  const getUsageLogs = ({
    rowsPerPage,
    offset,
    event,
    from_date,
    to_date,
    candidate_name,
    user_name,
    invoice_number,
    credit_type,
    user_id
  }) => {
    setLoading(true);

    props
      .getPrepaidUsageLogs(
        props.companyId,
        rowsPerPage,
        offset,
        event === 'All' ? null : event,
        from_date,
        to_date,
        candidate_name,
        user_name,
        invoice_number,
        credit_type,
        user_id
      )
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const getRowData = (values) => {
    let usageLogs = [];
    values.forEach((usage) => {
      let rowData = createData(
        getDetails(usage?.event),
        moment(usage?.created_at).utc().format(displayDateFormat),
        getCandidateName(usage),
        getDescription(usage),
        getCreditInRupee(usage?.amount, '-'),
        getCreditInRupee(usage?.available_balance, '-'),
        getUser(usage),
        actions(usage)
      );
      usageLogs.push(rowData);
    });
    return usageLogs;
  };

  const getDetails = (value) => {
    const event = eventList.filter((e) => e.value === value);
    return event[0]?.label || '-';
  };

  const getCandidateName = (usage) => {
    const name = usage?.candidate?.name || '-';
    const id = usage?.candidate?.id;
    const text = id ? `${capitalizeName(name)} (${id})` : capitalizeName(name);

    if (text === '-') {
      return text;
    } else if (usage?.candidate?.deleted_at) {
      return text;
    } else {
      return (
        <a
          href={`${AppConstants.baseURL}candidate/details?id=${id}&company_id=${usage?.company_id_fk}`}
          target='_blank'
          style={{ textDecoration: 'none' }}
        >
          {text}
        </a>
      );
    }
  };

  const getUser = (usage) => {
    const id = usage.user_id_fk;
    const name = capitalizeName(usage?.user?.name) || '-';
    let modifiedName = '';
    const companyUserMapping = usage?.user?.company_user_mapping;
    if (usage.event === 'ADDITIONAL_CHARGE') {
      modifiedName =
        companyUserMapping !== null
          ? `CA: ${name} (${id})`
          : usage?.payments_company_additional_charge?.ca_approve_or_rejected_by
          ? `CA: ${capitalizeName(
              usage?.payments_company_additional_charge?.ca_approve_or_rejected_by?.name
            )} (SA: ${name})`
          : `Auto-approved`;
    } else if (['RECHARGE', 'CREDITS_UNLOCKED'].includes(usage.event)) {
      return usage?.user
        ? companyUserMapping !== null
          ? `CA: ${name} (${id})`
          : `SA: ${name} (${id})`
        : 'External User';
    } else {
      modifiedName = companyUserMapping !== null ? `CA: ${name}  (${id})` : `SA: ${name} (${id})`;
    }
    return modifiedName;
  };

  const getAdditionalChargeType = (value) => {
    const type = ADC_TYPE_USAGE_LOG[value?.charge_type];

    return `${type}`;
  };

  const getAdditionalCheckType = (value) => {
    if (value) {
      const res = CHECK_TYPE_SHORT_VERSION[value];

      if (res) return res;
      else return value;
    }
    return '-';
  };

  const getDescription = (usage) => {
    switch (usage.event) {
      case 'CREDIT_NOTE':
        const detailsInvoice = usage?.invoice_details;
        let text = '';
        if (detailsInvoice) {
          const invoiceDate = moment(detailsInvoice?.invoice_date).format('DD-MM-YYYY');
          text = `(Inv No. ${detailsInvoice?.invoice_number || ''} : ${invoiceDate})`;
        }
        return `Credit Note ${text}`;
      case 'RECHARGE':
      case 'CREDIT_REFUND':
        return '-';
      case 'PACKAGE_BOUGHT':
      case 'PACKAGE_UNITS_CONVERTED_TO_CREDITS':
        return `${usage?.package_details ? usage.package_details?.name : '-'}`;
      case 'ADDON_BOUGHT':
      case 'CHECK_REFUND':
        return `${getAdditionalCheckType(usage?.check_type)}`;
      case 'ADDITIONAL_CHARGE':
      case 'ADDITIONAL_CHARGE_REFUND':
        return `${getAdditionalChargeType(
          usage?.payments_company_additional_charge
        )}: ${getAdditionalCheckType(usage?.check_type)}`;
      case 'REFUND':
        return `Refunded:  ${usage?.package_details ? usage.package_details?.name : '-'}`;
      case 'OTHER':
        return 'Other';
      case 'SPLIT_PURCHASE':
        return 'Split Purchase';
      case 'CREDITS_LOCKED':
      case 'CREDITS_UNLOCKED':
        return (
          <a
            className={
              usage?.prepaid_invoice_details?.proforma_number
                ? classes.invoiceUrl
                : classes.colorBlack
            }
            href={`${AppConstants.baseURL}invoice/document?invoice_id=${usage?.prepaid_invoice_details?.proforma_number}&is_proforma_invoice=true&company_type=PREPAID&company_id=${props.companyId}`}
            target='_blank'
          >
            {usage?.prepaid_invoice_details?.proforma_number || '-'}
          </a>
        );
      case 'FREE_CREDITS_TRIAL':
      case 'FREE_CREDITS':
      case 'BRIDGE_CREDITS':
      case 'CREDIT_DEDUCTION_BRIDGE_CREDITS':
      case 'CREDIT_DEDUCTION':
        return '-';
      case 'CHECK_VERIFICATION_TYPE_CONVERSION':
        return CHECK_TYPE_SHORT_VERSION[usage?.check_type];
      case 'ALUMNI_VERIFICATION_BONUS':
        return 'Credits Earned';
      default:
        return '-';
    }
  };

  const actions = (value) => {
    // if (['CREDIT_LIMIT_INCREASED', 'CREDIT_LIMIT_DECREASED', 'CREDIT_NOTE'].includes(value.event)) {
    //   return (
    //     <div>
    //       <span>Credit Balance</span>
    //     </div>
    //   );
    // } else {
    //   if (value.candidate && value.candidate.id && value.company_id_fk) {
    //     return (
    //       <a
    //         href={`${AppConstants.baseURL}candidate/details?id=${value.candidate.id}&company_id=${value.company_id_fk}`}
    //         target='_blank'
    //       >
    //         Candidate Log
    //       </a>
    //     );
    //   } else {
    //     return '-';
    //   }
    // }
    return '-';
  };

  const handleChangePage = (newPage) => {
    setOffset(newPage);
    setLoading(true);
    getUsageLogs({
      rowsPerPage,
      offset: newPage,
      event: event,
      from_date: DateFrom,
      to_date: DateTo ? DateTo : null,
      candidate_name: name,
      user_name: '',
      credit_type: getCreditOrDebit(),
      user_id: getUserName()
    });
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(parseInt(value, 10));
    setOffset(0);
    setLoading(true);
    getUsageLogs({
      rowsPerPage: parseInt(value, 10),
      offset: 0,
      event: event,
      from_date: DateFrom,
      to_date: DateTo ? DateTo : null,
      candidate_name: name,
      user_name: '',
      credit_type: getCreditOrDebit(),
      user_id: getUserName()
    });
  };

  const handleEvent = (e) => {
    setEvent(e.target.value);
  };

  const applyNameFilter = (cName) => {
    setName(cName);
  };

  const handleUserChange = (e) => {
    const value = e.target.value;
    setuserName(value);
  };

  const applyDateFilter = (dates) => {
    setDateRange(dates);
    if (dates[0] && dates[1]) {
      const date1 = new Date(dates[0]).toLocaleDateString('fr-CA');
      const date2 = new Date(dates[1]).toLocaleDateString('fr-CA');
      setDateFrom(date1);
      setDateTo(date2);
    } else {
      setDateFrom('');
      setDateTo('');
    }
  };

  const dateField = () => {
    return (
      <DatePicker
        selectsRange={true}
        startDate={dateRange[0]}
        endDate={dateRange[1]}
        onChange={(update) => {
          applyDateFilter(update);
        }}
        isClearable={true}
      />
    );
  };

  return (
    <>
      <Grid container>
        {
          //*! Filter items */
        }
        <Grid item container md={12} spacing={1}>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={1}>
            {/** Date Filter*/}
            <Grid item lg md sm={12} xs>
              <div className={classes.dateFilterWrapper}>
                <span className={classes.dateFilter}>Date Filter</span>
                {dateField()}
              </div>
            </Grid>
            {/** TXN Type Filter*/}
            <Grid style={{ paddingTop: '33px' }} item lg md sm={12} xs>
              <FormControl className={classes.wrapper} variant='outlined' size='small'>
                <InputLabel id='event-outlined-label'>Filter by TXN Type</InputLabel>
                <Select
                  labelId='event-outlined-label'
                  id='event-outlined'
                  value={event}
                  onChange={handleEvent}
                  label='Filter by TXN Type'
                >
                  <MenuItem value={'All'}>All</MenuItem>
                  {eventList.map((event, i) => (
                    <MenuItem value={event.value} key={i}>
                      {event.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/** User Filter*/}
            <Grid style={{ paddingTop: '33px' }} item lg md sm={12} xs>
              <FormControl className={classes.wrapper} variant='outlined' size='small'>
                <InputLabel id='event-outlined-label'>Filter by User</InputLabel>
                <Select
                  labelId='event-outlined-label'
                  id='event-outlined'
                  value={userName}
                  onChange={handleUserChange}
                  label='Filter by User'
                  size={'small'}
                >
                  <MenuItem value={'All'}>All</MenuItem>
                  {/** Map User list from API */}
                  {companyUserList.length &&
                    companyUserList.map((user) => (
                      <MenuItem value={user.user_id} key={user.user_id}>
                        {`${user.isCompanyAdmin ? 'CA' : 'SA'}: ${capitalizeName(
                          user.user_name
                        )} (${user.user_id})`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            {/* Credit/Debit Filter */}
            <Grid style={{ paddingTop: '33px' }} item lg md sm={12} xs>
              <FormControl className={classes.wrapper} variant='outlined' size='small'>
                <InputLabel id='event-outlined-label'>Filter by Credit/Debit</InputLabel>
                <Select
                  labelId='event-outlined-label'
                  id='event-outlined'
                  value={creditType}
                  onChange={handleCreditDebitFilter}
                  label='Filter by Credit/Debit'
                  size={'small'}
                >
                  <MenuItem value={'All'}>All</MenuItem>
                  <MenuItem value={'Credit'}>Credit Only</MenuItem>
                  <MenuItem value={'Debit'}>Debit Only</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* Search by candidate name Filter */}
            <Grid style={{ paddingTop: '33px' }} item lg md={12} sm={12} xs>
              <SearchInput
                isBackSpaceCallEnabled={true}
                applyMinFont={true}
                placeholder={'Candidate Name'}
                handleSearch={debounce((name) => {
                  applyNameFilter(name?.trim());
                }, 500)}
              />
            </Grid>
            <Grid style={{ paddingTop: '33px' }} item lg md={12} sm={12} xs>
              {parseInt(companyCreditBalance.excessBridgeCredits) !== 0 && (
                <div className={classes.excessBridgeText}>
                  <span> Excess Bridge Credits: </span>
                  <span
                    className={`${classes.excessBridgeValueContainer} ${
                      getExcessBridgeCreditValue(companyCreditBalance?.excessBridgeCredits)
                        .isNegative
                        ? classes.redColorText
                        : classes.orangeColorText
                    } `}
                  >
                    <span>
                      {getExcessBridgeCreditValue(companyCreditBalance?.excessBridgeCredits)
                        .isNegative
                        ? '-'
                        : ''}
                    </span>
                    <span className={classes.rupeeSymbol}>&#8377;</span>
                    <span
                      className={
                        getExcessBridgeCreditValue(companyCreditBalance?.excessBridgeCredits)
                          .isNegative
                          ? classes.redColorText
                          : classes.orangeColorText
                      }
                    >
                      {convertToIndianNumeration(
                        getExcessBridgeCreditValue(companyCreditBalance?.excessBridgeCredits)
                          .isNegative
                          ? getExcessBridgeCreditValue(companyCreditBalance?.excessBridgeCredits)
                              .value * -1
                          : getExcessBridgeCreditValue(companyCreditBalance?.excessBridgeCredits)
                              .value
                      )}
                    </span>
                  </span>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>

        {
          //*! Table content */
        }
        <Grid item md={12}>
          <UsageLogsTable
            headCells={headCells}
            rowData={rows}
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            page={offset}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            loading={loading}
          />
        </Grid>
      </Grid>
    </>
  );
}
