import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Chip
} from '@material-ui/core';
import moment from 'moment';

import Table from '../../../../../core-components/Table';
import styles from './PendingOrders.module.scss';
import { PURCHASE_STATUS, displayDateFormat } from '../../../../../core-components/CommonConstants';
import BasicMenu from './BasicMenu';
import { convertToIndianNumeration } from '../../../../../utils/Utlities';
import AppConstants from '../../../../../core-components/AppConstants';
import { isEmpty } from 'lodash';
import { capitalizeName } from '../../../frontend-common/utils/Utilities';

const headerColumn = [
  {
    id: 'requestedDate',
    label: 'Requested Date',
    align: 'center'
  },
  {
    id: 'cancelledOrExpiredDate',
    label: 'Cancelled/ Expired Date',
    align: 'left'
  },
  {
    id: 'txnType',
    label: 'Txn Type',
    align: 'left'
  },
  {
    id: 'document',
    label: 'Document',
    align: 'center'
  },
  {
    id: 'user',
    label: 'User',
    align: 'left'
  },
  {
    id: 'amount',
    label: 'Amount',
    align: 'center'
  },
  {
    id: 'info',
    label: 'Additional Info / Actions',
    align: 'center'
  }
];
function createTableRow(
  requestedDate,
  cancelledOrExpiredDate,
  txnType,
  document,
  user,
  amount,
  info,
  showCancelledOrExpiredDate
) {
  if (showCancelledOrExpiredDate) {
    return {
      requestedDate,
      cancelledOrExpiredDate,
      txnType,
      document,
      user,
      amount,
      info
    };
  } else {
    return {
      requestedDate,
      txnType,
      document,
      user,
      amount,
      info
    };
  }
}

export default function RechargeLog(props) {
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [purchaseStatus, setPurchaseStatus] = useState('ALL');
  const [showCancelledOrExpiredDate, setCancelledOrExpiredDate] = useState(false);
  const [headCells, setHeadCells] = useState(headerColumn);
  const { invalidateCompanyPendingOrder } = props;
  useEffect(() => {
    pendingOrderAPICall({
      companyId: props.companyId,
      limit: rowsPerPage,
      offset: offset,
      status: purchaseStatus == 'ALL' ? null : purchaseStatus
    });
    setTotalCount(props.companyPendingOrders.count);
    return () => {
      invalidateCompanyPendingOrder();
    };
  }, []);

  useEffect(() => {
    let rowsData =
      props.companyPendingOrders &&
      props.companyPendingOrders.paymentOrders &&
      getData(props.companyPendingOrders.paymentOrders);
    setRows(rowsData);
    setTotalCount(props.companyPendingOrders.count);
  }, [props.companyPendingOrders]);

  useEffect(() => {
    if (!showCancelledOrExpiredDate)
      setHeadCells(headCells.filter((cells) => cells.id !== 'cancelledOrExpiredDate'));
    else {
      const newArray = [
        ...headCells.slice(0, 1),
        {
          id: 'cancelledOrExpiredDate',
          label: 'Cancelled/ Expired Date',
          align: 'left'
        },
        ...headCells.slice(1)
      ];
      setHeadCells(newArray);
      pendingOrderAPICall({
        companyId: props.companyId,
        limit: rowsPerPage,
        offset: offset,
        status: purchaseStatus == 'ALL' ? null : purchaseStatus
      });
    }
  }, [showCancelledOrExpiredDate]);

  const pendingOrderAPICall = ({
    companyId = props.companyId,
    limit = rowsPerPage,
    offset = offset,
    status = purchaseStatus
  }) => {
    props.getCompanyPendingOrders({
      company_id: companyId,
      limit: limit,
      offset: offset,
      status: status == 'ALL' ? null : status
    });
    setOffset(offset);
    setRowsPerPage(limit);
    setPurchaseStatus(status);
  };

  const getDocument = (proformaNumber) => {
    return (
      <a
        className={proformaNumber ? styles.invoiceUrl : styles.colorBlack}
        href={`${AppConstants.baseURL}invoice/document?invoice_id=${proformaNumber}&is_proforma_invoice=true&company_type=PREPAID&company_id=${props.companyId}`}
        target='_blank'
      >
        {proformaNumber || '-'}
      </a>
    );
  };

  const getUser = (data) => {
    return data?.requestedBy?.isRequestedBySa
      ? `CA: ${capitalizeName(data?.requestedBy?.userName)} (SA: ${capitalizeName(
          data?.requestedBy?.saUserName
        )})`
      : `CA: ${capitalizeName(data?.requestedBy?.userName)}`;
  };
  const getAmount = (amount) => {
    return (
      <span className={styles.rupee}>
        {amount ? <span>&#8377;</span> : ''}
        {convertToIndianNumeration(amount || 0) || '-'}
      </span>
    );
  };
  const getData = (paymentOrders) => {
    let tableRows = [];
    paymentOrders.map((e) => {
      tableRows.push(
        createTableRow(
          moment(e.requestedDate).utc().format(displayDateFormat),
          e.cancelledOrExpiredDate
            ? moment(e.cancelledOrExpiredDate).utc().format(displayDateFormat)
            : '-',
          getTxnType(e.status),
          getDocument(e.proformaNumber),
          getUser(e),
          getAmount(e?.amount),
          getActionBtn(e),
          showCancelledOrExpiredDate
        )
      );
    });

    return tableRows;
  };

  const getTxnType = (status) => {
    return (
      <div>
        Purchase (Recharge)
        <span className={styles.chips}>
          <Chip
            label={status}
            color={status === 'PENDING' ? 'primary' : 'default'}
            variant='outlined'
          />
        </span>
      </div>
    );
  };
  const getActionBtn = (order) => {
    return (
      <BasicMenu
        order={order}
        caList={props.companyPendingOrders.companyAdmins}
        companyId={props.companyId}
        balance={props.companyCreditBalance?.credits?.balance}
        tax={props?.companyCreditBalance?.credits?.company?.tax}
        companyName={props?.companyCreditBalance?.credits?.company?.name}
        pendingOrderAPICall={pendingOrderAPICall}
      />
    );
  };

  const handleChangePage = (newPage) => {
    pendingOrderAPICall({ offset: newPage });
  };

  const handleChangeRowsPerPage = (value) => {
    pendingOrderAPICall({ limit: value, offset: 0 });
  };

  const handlePurchaseStatus = (e) => {
    pendingOrderAPICall({
      offset: 0,
      limit: 10,
      status: e.target.value
    });
  };
  const handleCheckedBox = (e) => {
    setCancelledOrExpiredDate((prev) => !prev);
  };
  return (
    <>
      <Grid container md={12} spacing={2} className={styles.containerPendingOrder}>
        <Grid item md={3}>
          <div className={styles.filter}>
            <label className={styles.label}>Filter by Purchase Status</label>
            <FormControl variant='outlined' size='small'>
              <Select
                labelId='demo-simple-select-outlined-label'
                id='demo-simple-select-outlined'
                value={purchaseStatus}
                onChange={handlePurchaseStatus}
              >
                <MenuItem value='ALL'>ALL</MenuItem>
                {PURCHASE_STATUS.map((event) => (
                  <MenuItem value={event.value}>{event.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={showCancelledOrExpiredDate}
                onChange={handleCheckedBox}
                name='showCancelledOrExpiredDate'
                color='primary'
              />
            }
            label='Show Cancelled/ Expired Date'
          />
        </Grid>
      </Grid>
      <Grid container></Grid>
      <Table
        headCells={headCells}
        rowData={rows}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={offset}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        loading={isEmpty(props.companyPendingOrders) || props.companyPendingOrders?.loading}
        noContentText='No Pending Order'
      />
    </>
  );
}
