import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, isEmpty } from 'lodash';
import { toast } from 'react-toastify';

import {
  invalidateCompanySettings as invalidateCompanySettingsAPI,
  getCompanySettingsById as getCompanySettingsAPI
} from '../../../../actions/admin';
import validationSchema from './EditSettings.validation';
import { editCompanySettings as editCompanySettingsAPI } from '../../../../api/admin';

import { errToastMessage, getErrorMessage, validateOperation } from '../../../../utils/Utlities';
import AppConstants from '../../../../core-components/AppConstants';
import { capitalizeName } from '../../frontend-common/utils/Utilities';

const isCreateFlow = new URLSearchParams(document.location.search).get('create') || false;
const packageType = new URLSearchParams(document.location.search).get('package');

const mapStateToProps = (state) => {
  return {
    companySettings: (!isEmpty(state.companySettings) && state.companySettings) || {},
    profile: state.profile && !isEmpty(state.profile) ? state.profile : null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanySettings: (companyId) => dispatch(getCompanySettingsAPI(companyId)),
    invalidateCompanySettings: () => dispatch(invalidateCompanySettingsAPI())
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        companyId: '',
        pointPersonSales: '',
        accountExecutive: '',
        notes: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        pinCode: '',
        landmark: '',
        ats: '',
        hrms: '',
        communicationTool: ''
      };
      let propsValue = {};
      let companyId = new URLSearchParams(document.location.search).get('company_id');
      let isCreateFlow = new URLSearchParams(document.location.search).get('create') || false;

      if (
        props.companySettings &&
        !isEmpty(props.companySettings) &&
        companyId &&
        !props?.companySettings?.loading
      ) {
        let data = props.companySettings;
        propsValue = {
          companyId: Number(companyId),
          addressLine1:
            data.companyAddress && data.companyAddress.addressLine1
              ? data.companyAddress.addressLine1
              : '',
          addressLine2:
            data.companyAddress && data.companyAddress.addressLine2
              ? data.companyAddress.addressLine2
              : '',
          city: data.companyAddress && data.companyAddress.city ? data.companyAddress.city : '',
          state: data.companyAddress && data.companyAddress.state ? data.companyAddress.state : '',
          country:
            data.companyAddress && data.companyAddress.country
              ? data.companyAddress.country
              : isCreateFlow
              ? 'India'
              : '',
          pinCode:
            data.companyAddress && data.companyAddress.pinCode ? data.companyAddress.pinCode : '',
          landmark:
            data.companyAddress && data.companyAddress.landmark ? data.companyAddress.landmark : '',
          companyAgreements: data.companyAgreements || []
        };

        if (data && data.companyMetadata) {
          for (let i = 0; i < data.companyMetadata.length; i++) {
            if (data.companyMetadata[i].type === 'point_person_sales') {
              propsValue.pointPersonSales = capitalizeName(data.companyMetadata[i].value);
            }
            if (data.companyMetadata[i].type === 'account_executive') {
              propsValue.accountExecutive = capitalizeName(data.companyMetadata[i].value);
            }
            if (data.companyMetadata[i].type === 'notes') {
              propsValue.notes = data.companyMetadata[i].value;
            }
            if (data.companyMetadata[i].type === 'ats') {
              propsValue.ats = data.companyMetadata[i].value;
            }
            if (data.companyMetadata[i].type === 'hrms') {
              propsValue.hrms = data.companyMetadata[i].value;
            }
            if (data.companyMetadata[i].type === 'communication_tool') {
              propsValue.communicationTool = data.companyMetadata[i].value;
            }
          }
        }
      }
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    validateOnChange: true,
    handleSubmit: (values, { setSubmitting, dirty, props }) => {
      if (validateOperation(props.profile)) {
        editCompanySettingsAPI(values)
          .then(() => {
            toast.success('Details updated successfully');
            if (isCreateFlow) {
              window.open(
                `${AppConstants.baseURL}company/financeConfig/view?create=true&id=${values.companyId}`,
                '_self'
              );
            } else {
              props.history.push({
                pathname: '/company/additionalSettings/view',
                search: `?company_id=${values.companyId}`
              });
            }
          })
          .catch((error) => {
            console.error(error);
            errToastMessage(error);
          });
      } else {
        setSubmitting(false);
        errToastMessage('Only admin is allowed to perform this operation');
      }
    },
    displayName: 'Edit Addtional Settings'
  })
);
