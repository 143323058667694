import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, has, toLower } from 'lodash';
import { toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import { errToastMessage, getIntlPhoneNumberObject } from '../../../../utils/Utlities';
import {
  getCompanyAdmin as getCompanyAdminAPI,
  getCompanyCategories as getCompanyCategoriesAPI
} from '../../../../actions/admin';
import {
  updateCompanyAdmin as updateCompanyAdminAPI,
  createCompanyAdmin as createCompanyAdminAPI
} from '../../../../api/admin';
import validationSchema from './CreateAdmin.validation';
import { capitalizeName } from '../../frontend-common/utils/Utilities';

const mapStateToProps = (state) => {
  return {
    getCategories: state.companyCategories.all || []
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyAdmin: (companyId, limit, offset) =>
      dispatch(getCompanyAdminAPI(companyId, limit, offset)),
    getCompanyCategories: (companyId, limit, offset, name) =>
      dispatch(getCompanyCategoriesAPI(companyId, limit, offset, name))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      const _openAddUserModal = new URLSearchParams(document.location.search).get(
        '_openAddUserModal'
      );
      let propsValue = {};

      if (has(props.details, 'user')) {
        propsValue = props?.details?.user;

        if (props?.details?.user?.mobile?.length > 10) {
          let phoneNumberObj = getIntlPhoneNumberObject(props?.details?.user?.mobile);
          if (phoneNumberObj) {
            propsValue.mobile = phoneNumberObj.nationalNumber;
            propsValue.countryCode = `+${phoneNumberObj.countryCallingCode}`;
          }
        } else {
          propsValue.mobile = props?.details?.user?.mobile;
          propsValue.countryCode = props?.details?.user?.countryCode;
        }
      } else if (_openAddUserModal) {
        const data = secureLocalStorage.getItem('NEW_USER') || {};
        propsValue = { ...propsValue, ...data };
      } else {
        propsValue = {};
      }
      let storedValues = {
        name: '',
        email: '',
        mobile: '',
        countryCode: '+91',
        isValidPhone: true,
        designation: '',
        accessLevel: 5,
        linkedInUrl: props?.details?.linkedInUrl || '',
        userCategoryIds: []
      };
      if (propsValue.name) {
        propsValue.name = capitalizeName(propsValue.name);
      }
      if (propsValue.email) {
        propsValue.email = toLower(propsValue.email);
      }
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    handleSubmit: (values, { setSubmitting, props }) => {
      let finalValues;
      setSubmitting(true);
      if (props.adminId) {
        finalValues = {
          companyId: props.companyId,
          adminId: props.adminId,
          name: values.name,
          email: values.email,
          mobile: values?.mobile ? values?.countryCode + values?.mobile : values?.mobile,
          designation: values.designation,
          linkedInUrl: values.linkedInUrl,
          userType: values.accessLevel === 5 ? 'admin' : 'hr',
          userCategoryIds: values.userCategoryIds
        };

        updateCompanyAdminAPI(finalValues)
          .then(() => {
            setSubmitting(false);
            props.getCompanyAdmin(props.companyId, props.rowsPerPage, props.offset);
            toast.info('Company Admin Updated Successfully');
            props.handleClose();
          })
          .catch((error) => {
            console.error(error);
            setSubmitting(false);
            errToastMessage(error);
          });
      }
    },
    displayName: 'Create Services'
  })
);
