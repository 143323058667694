import React, { useState } from 'react';
import { forEach, isEmpty, has } from 'lodash';

import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import Table from '../../../core-components/Table';
import AppConstants from '../../../core-components/AppConstants';
import BreadCrumbs from '../../../core-components/BreadCrumbs';
import Rating from '@material-ui/lab/Rating';
import { capitalizeName } from '../frontend-common/utils/Utilities';

const useStyles = makeStyles({
  heading: {
    paddingTop: '20px',
    paddingBottom: '20px'
  },
  headingSubText: {
    color: '#8c8c8c',
    fontSize: '18px'
  }
});

function createData(incrementId, candidateId, stars, message, candidate, createdAt) {
  return { incrementId, candidateId, stars, message, candidate, createdAt };
}

const headCells = [
  {
    id: 'incrementId',
    label: '#',
    align: 'left'
  },
  {
    id: 'candidateId',
    label: 'Candidate ID (SV ID)',
    align: 'left'
  },
  {
    id: 'stars',
    label: 'Stars',
    align: 'center'
  },
  {
    id: 'message',
    label: 'Message',
    align: 'center'
  },
  {
    id: 'candidate',
    label: 'Candidate',
    align: 'center'
  },
  {
    id: 'createdAt',
    label: 'Date of Feedback',
    align: 'center'
  }
];

export default (props) => {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);

  const breadCrumbsArray = [{ label: 'Home', href: `${AppConstants.baseURL}dashboard` }];

  React.useEffect(() => {
    props.invalidateFeedback();
    getLogs(rowsPerPage, offset);
  }, []);

  React.useEffect(() => {
    if (
      !isEmpty(props.feedback) &&
      has(props.feedback, 'loading') &&
      props?.feedback?.loading === false
    ) {
      let rows =
        props?.feedback?.all[offset] &&
        props?.feedback?.all[offset]?.rows &&
        props?.feedback?.all[offset]?.rows?.length
          ? getRowData(props.feedback.all[offset].rows)
          : [];
      setData(rows);
      setCount(props.feedback.all[offset].count || 0);
      setLoading(props.feedback.loading);
    }
  }, [props.feedback]);

  const getLogs = (rowsPerPage, offset) => {
    setLoading(true);
    props.getFeedback(rowsPerPage.toString(), offset.toString(), null);
  };

  const getRowData = (values) => {
    let logsRow = [];
    forEach(values, function (value, key) {
      if (!isEmpty(value)) {
        let rowData = createData(
          key + 1 + offset * rowsPerPage,
          value.candidateId,
          value.stars ? getStars(value.stars) : 'NA',
          value.message,
          value.candidate ? capitalizeName(value.candidate.name) : 'User',
          (value.createdAt &&
            moment(value.createdAt).isValid() &&
            moment(value.createdAt).format('DD MMM YYYY hh:mm A')) ||
            null
        );
        logsRow.push(rowData);
      }
    });
    return logsRow;
  };

  const getStars = (value) => {
    return <Rating name='disabled' value={value} disabled size='small' />;
  };

  const handleChangePage = (newPage) => {
    setOffset(newPage);
    getLogs(rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (value) => {
    setOffset(0);
    setRowsPerPage(parseInt(value, 10));
    getLogs(parseInt(value, 10), 0);
  };

  return (
    <>
      <BreadCrumbs linksArray={breadCrumbsArray} current={'feedback'} />
      <div className={classes.heading}>
        <Typography variant='h5' component='h5'>
          {'Feedback'}
        </Typography>
      </div>
      <Table
        headCells={headCells}
        rowData={data}
        totalCount={count}
        rowsPerPage={rowsPerPage}
        page={offset}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        loading={loading}
      />
    </>
  );
};
