import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { forEach, isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, CircularProgress, Grid, Tooltip, Zoom, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Table from '../../../../core-components/Table';
import AppConstants from '../../../../core-components/AppConstants';
import DetailsCard from '../../../../core-components/DetailsCard';
import BreadCrumbs from '../../../../core-components/BreadCrumbs';
import ConfirmationPopup from '../../../../core-components/ConfirmationPopup';
import { getToken } from '../../../../api/storage';
import {
  getCompanySettings as getCompanySettingsAPI,
  deleteAgreementDoc as deleteAgreementDocAPI
} from '../../../../api/admin';
import {
  errToastMessage,
  getCityNameWithoutAsterisk,
  toCamelCase,
  validateOperation
} from '../../../../utils/Utlities';
import { capitalizeName } from '../../frontend-common/utils/Utilities';

const useStyles = makeStyles({
  logo: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  heading: {
    padding: '10px'
  },
  navBtn: {
    float: 'right',
    marginBottom: '-35px'
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '60vh',
    alignItems: 'center'
  },
  headingSubText: {
    color: '#8c8c8c',
    fontSize: '18px'
  },
  documentButton: {
    height: '25px',
    fontSize: '12px'
  },
  deleteIcon: {
    verticalAlign: 'middle',
    padding: '5px !important'
  }
});

const STATUS_LABEL = {
  empty: 'EMPTY',
  draft_review: 'DRAFT REVIEW',
  sent_to_client: 'SENT TO CLIENT',
  legal_review: 'LEGAL REVIEW',
  insufficient: 'INSUFFICIENT',
  executed: 'EXECUTED'
};

const DOCUMENT_TYPES_LABEL = {
  master_service_agreement: 'Master Service Agreement',
  letter_of_authorization: 'Letter Of Authorization',
  letter_of_consent: 'Letter Of Consent',
  pricing: 'Pricing',
  scope_of_work: 'Scope Of Work',
  other: 'Other'
};

export default function ViewCompanySettings(props) {
  const classes = useStyles();
  const [settingsData, setSettingsData] = useState('');
  const [companyId, setCompanyId] = useState(
    new URLSearchParams(document.location.search).get('company_id') || null
  );
  const [companyName, setCompanyName] = useState('Company Name');
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [agreementData, setAgreementData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [agreementUuid, setAgreementUuid] = useState(null);
  const [docUuid, setDocUuid] = useState(null);
  const [openApprovalModal, setOpenApprovalModal] = useState(false);

  let breadCrumbsArray = [
    { label: 'Home', href: `${AppConstants.baseURL}dashboard` },
    { label: 'Companies', href: `${AppConstants.baseURL}companies` },
    {
      label: companyName,
      href: `${AppConstants.baseURL}company/view?id=${companyId}`
    }
  ];

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    const rows =
      (settingsData &&
        settingsData.companyAgreements &&
        settingsData.companyAgreements.length &&
        getRowData(settingsData.companyAgreements)) ||
      [];
    setAgreementData(rows);
  }, [settingsData]);

  const getSettings = () => {
    //Call API here
    getCompanySettingsAPI(companyId)
      .then((response) => {
        setLoading(false);
        let data = toCamelCase(response.data.data);
        if (data && data.companyMetadata) {
          for (let i = 0; i < data.companyMetadata.length; i++) {
            if (data.companyMetadata[i].type === 'point_person_sales') {
              data.pointPersonSales = data.companyMetadata[i].value;
            }
            if (data.companyMetadata[i].type === 'account_executive') {
              data.accountExecutive = data.companyMetadata[i].value;
            }
            if (data.companyMetadata[i].type === 'notes') {
              data.notes = data.companyMetadata[i].value;
            }
            if (data.companyMetadata[i].type === 'ats') {
              data.ats = data.companyMetadata[i].value;
            }
            if (data.companyMetadata[i].type === 'hrms') {
              data.hrms = data.companyMetadata[i].value;
            }
            if (data.companyMetadata[i].type === 'communication_tool') {
              data.communication_tool = data.companyMetadata[i].value;
            }
          }
        } else {
          data.point_person_sales = '';
          data.account_executive = '';
          data.notes = '';
          data.ats = '';
          data.hrms = '';
          data.communication_tool = '';
        }
        setSettingsData(data);
        setCompanyName(data.name || 'Company Name');
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        errToastMessage(error);
        setTimeout(() => {
          window.open(`${AppConstants.baseURL}dashboard`, '_self');
        }, 4000);
      });
  };

  const getRowData = (values) => {
    let aggreementRowData = [];

    forEach(values, function (value, index) {
      if (!isEmpty(value)) {
        let rowData = createData(
          index + 1 + offset * rowsPerPage,
          STATUS_LABEL[value.status] || null,
          (value.executionDate &&
            moment(value.executionDate).isValid() &&
            moment(value.executionDate).format('DD MMM YYYY')) ||
            null,
          (value.expiryDate &&
            moment(value.expiryDate).isValid() &&
            moment(value.expiryDate).format('DD MMM YYYY')) ||
            null,
          value.companyAgreementDocuments.length
            ? getDocumentAction(value.companyAgreementDocuments)
            : '( None )'
        );
        aggreementRowData.push(rowData);
      }
    });
    return aggreementRowData;
  };

  function createData(incrementId, status, executionDate, expiryDate, documents) {
    return { incrementId, status, executionDate, expiryDate, documents };
  }

  function getDocumentAction(documents) {
    return (
      <div>
        {documents.map((value, index) => {
          return (
            <div key={index}>
              {value.file ? (
                <>
                  <IconButton
                    aria-label='delete'
                    onClick={() => {
                      if (validateOperation(props.profile)) {
                        setAgreementUuid(value.companyAgreementUuid);
                        setDocUuid(value.uuid);
                        setShowConfirmationPopup(true);
                      } else {
                        errToastMessage('Only admin is allowed to perform this operation');
                      }
                    }}
                    className={classes.deleteIcon}
                  >
                    <Tooltip TransitionComponent={Zoom} title='Delete' arrow={true}>
                      <DeleteIcon color='secondary' />
                    </Tooltip>
                  </IconButton>
                  <Button
                    color='primary'
                    className={classes.documentButton}
                    endIcon={<VisibilityIcon />}
                    onClick={() => {
                      window.open(
                        `${AppConstants.developmentAPIURL}auth/file?uuid=${
                          value?.file?.uuid
                        }&token=${getToken()}` || '#',
                        '_blank'
                      );
                    }}
                  >
                    {value.documentType ? DOCUMENT_TYPES_LABEL[value.documentType] : null}
                  </Button>
                </>
              ) : value.documentType ? (
                DOCUMENT_TYPES_LABEL[value.documentType]
              ) : null}
            </div>
          );
        })}
      </div>
    );
  }

  const approvalData = [
    {
      label: `${
        !isEmpty(settingsData.additionalChargesSettings)
          ? settingsData.additionalChargesSettings.typeOfAutoApprove === 'ALWAYS'
            ? 'Always Auto approve'
            : settingsData.additionalChargesSettings.typeOfAutoApprove === 'LIMIT'
            ? `Auto approve till Rs. ${
                settingsData.additionalChargesSettings.amount || 0
              } (per check)`
            : 'Never auto approve additional charges'
          : 'Additional charges setting is not set yet.'
      }`,
      value: ''
    }
  ];

  const data = [
    {
      label: 'Sales POC',
      value: capitalizeName(settingsData.pointPersonSales) || null
    },
    {
      label: 'Customer Success POC',
      value: capitalizeName(settingsData.accountExecutive) || null
    },
    {
      label: 'Notes',
      value: settingsData.notes || null
    },
    {
      label: 'Address Line 1',
      value:
        settingsData.companyAddress && settingsData.companyAddress.addressLine1
          ? settingsData.companyAddress.addressLine1
          : ''
    },
    {
      label: 'Address Line 2',
      value:
        settingsData.companyAddress && settingsData.companyAddress.addressLine2
          ? settingsData.companyAddress.addressLine2
          : ''
    },
    {
      label: 'City',
      value:
        settingsData.companyAddress && settingsData.companyAddress.city
          ? getCityNameWithoutAsterisk(settingsData.companyAddress.city)
          : ''
    },
    {
      label: 'State',
      value:
        settingsData.companyAddress && settingsData.companyAddress.state
          ? getCityNameWithoutAsterisk(settingsData.companyAddress.state)
          : ''
    },
    {
      label: 'Country',
      value:
        settingsData.companyAddress && settingsData.companyAddress.country
          ? getCityNameWithoutAsterisk(settingsData.companyAddress.country)
          : ''
    },
    {
      label: 'Pincode',
      value:
        settingsData.companyAddress && settingsData.companyAddress.pinCode
          ? settingsData.companyAddress.pinCode
          : ''
    },
    {
      label: 'Landmark',
      value:
        settingsData.companyAddress && settingsData.companyAddress.landmark
          ? settingsData.companyAddress.landmark
          : ''
    },
    {
      label: 'ATS',
      value: settingsData.ats || ''
    },
    {
      label: 'HRMS',
      value: settingsData.hrms || ''
    },
    {
      label: 'Communication Tool',
      value: settingsData.communication_tool || ''
    }
  ];

  const headCells = [
    {
      id: 'incrementId',
      label: '#',
      align: 'center'
    },
    {
      id: 'status',
      label: 'Status',
      align: 'left'
    },
    {
      id: 'executionDate',
      label: 'Execution Date',
      align: 'left'
    },
    {
      id: 'expiryDate',
      label: 'Expiry Date',
      align: 'left'
    },
    {
      id: 'documents',
      label: 'Documents',
      align: 'left'
    }
  ];

  const handleDeleteDocument = () => {
    deleteAgreementDocAPI(companyId, agreementUuid, docUuid)
      .then((response) => {
        setAgreementUuid(null);
        setDocUuid(null);
        toast.success('Document deleted successfully');
        setShowConfirmationPopup(false);
        getSettings();
      })
      .catch((error) => {
        setAgreementUuid(null);
        setDocUuid(null);
        console.error(error);
        errToastMessage(error);
        setShowConfirmationPopup(false);
      });
  };

  return (
    <>
      {!loading && settingsData ? (
        <>
          <BreadCrumbs linksArray={breadCrumbsArray} current={'Additional Information'} />

          <div className={classes.heading}>
            <Typography variant='h5' component='h5'>
              {'Additional Information'}
            </Typography>
            <Typography className={classes.headingSubText} variant='h6' component='h6'>
              {companyName}
            </Typography>
          </div>
          <div className={classes.navBtn}>
            <Button
              color='primary'
              endIcon={<EditIcon />}
              onClick={() => {
                props.invalidateCompanySettings();
                props.history.push({
                  pathname: '/company/additionalSettings/edit',
                  search: `?company_id=${companyId}`
                });
              }}
            >
              {'Edit'}
            </Button>
          </div>
          {/* the above button needs a pop and dynamic data depending on API */}
          <DetailsCard data={data} heading={'Additional Information'} />

          <div className={classes.heading}>
            <Typography variant='h6' component='h6'>
              {'Agreement Details'}
            </Typography>
          </div>

          <Table
            headCells={headCells}
            rowData={agreementData}
            totalCount={count}
            rowsPerPage={rowsPerPage}
            page={offset}
            noPagination={true}
            handleChangePage={() => {}}
            handleChangeRowsPerPage={() => {}}
            loading={loading}
            filterArr={[]}
          />

          {showConfirmationPopup ? (
            <ConfirmationPopup
              open={showConfirmationPopup}
              title={'Are you sure ?'}
              content={'Are you sure you want to delete this document ?'}
              handleSubmit={() => {
                handleDeleteDocument();
              }}
              handleClose={() => {
                setShowConfirmationPopup(false);
              }}
              buttonLabel={'Confirm'}
            />
          ) : null}
        </>
      ) : (
        <Grid container spacing={2} className={classes.spinnerContainer}>
          <CircularProgress />
        </Grid>
      )}
    </>
  );
}
