import React, { useEffect, useState } from 'react';
import { forEach, isEmpty, has, debounce, toLower } from 'lodash';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, Zoom, Typography } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Grid, Box } from '@material-ui/core';

import { COMPANY_STATUSES } from '../../../utils/Statuses';
import Table from '../../../core-components/Table';
import DeletePopup from '../../../core-components/ConfirmationPopup';
import SearchInput from '../../../core-components/SearchInput';
import Button from '../../../core-components/Button';
import BreadCrumbs from '../../../core-components/BreadCrumbs';
import AppConstants from '../../../core-components/AppConstants';
import Dropdown from '../../../core-components/DropdownInput';
import { ADMIN_ACCESS_LEVEL } from '../../../utils/Statuses';
import { deleteAdmin as deleteAdminAPI } from '../../../api/admin';
import AppHelper from '../../../core-components/AppHelper';
import { getErrorMessage, accessCookie, jwtParser, errToastMessage } from '../../../utils/Utlities';

import ViewModal from './ViewAdmin';
import CreateModal from './CreateAdmin';
import { toast } from 'react-toastify';
import Switch from '@material-ui/core/Switch';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import ConfirmationPopup from '../../../core-components/ConfirmationPopup';
import { capitalizeName } from '../frontend-common/utils/Utilities';

function createData(
  index,
  id,
  name,
  email,
  mobile,
  accessLevel,
  status,
  createdAt,
  accountStatus,
  actions,
  forceLogout
) {
  return {
    index,
    id,
    name,
    email,
    mobile,
    accessLevel,
    status,
    createdAt,
    accountStatus,
    actions,
    forceLogout
  };
}

const useStyles = makeStyles({
  btnDiv: {
    display: 'flex'
  },
  deleteBtn: {
    color: '#467eac',
    cursor: 'pointer'
  },
  editBtn: {
    color: '#3788ac',
    cursor: 'pointer'
  },
  logoutBtn: {
    color: '#ECA06A',
    cursor: 'pointer'
  }
});

const headCells = [
  {
    id: 'index',
    label: '#',
    align: 'left'
  },
  {
    id: 'id',
    label: 'User ID',
    align: 'center'
  },
  {
    id: 'name',
    label: ' Name',
    align: 'center'
  },

  {
    id: 'email',
    label: 'Email',
    align: 'center'
  },
  {
    id: 'mobile',
    label: 'Mobile',
    align: 'center'
  },

  {
    id: 'accessLevel',
    label: 'Access Level',
    align: 'center'
  },
  {
    id: 'status',
    label: 'Status',
    align: 'center'
  },
  {
    id: 'createdAt',
    label: 'Created At',
    align: 'center'
  },
  {
    id: 'accountStatus',
    label: '',
    align: 'center'
  },
  {
    id: 'actions',
    label: '',
    align: 'left'
  },
  {
    id: 'forceLogout',
    label: '',
    align: 'center'
  }
];

export default function EnhancedTable(props) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const [title, setTitle] = useState(null);
  const [adminId, setAdminId] = useState(null);
  const [adminDetails, setAdminDetails] = useState(null);
  const [adminName, setAdminName] = useState(null);

  const [includeCompanyAdmins, setIncludeCompanyAdmins] = useState(false);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [accessLevel, setAccessLevel] = useState(null);
  const [status, setStatus] = useState(null);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [logoutAdminId, setLogoutAdminId] = useState(null);
  const [disabledDeleteBtn, setDisabledDeleteBtn] = useState(false);

  const classes = useStyles();

  const hostName = AppHelper.getEnv();
  const cookieName = hostName !== 'prod' ? `sa_node_auth_token_${hostName}` : 'sa_node_auth_token';
  const token = accessCookie(cookieName); //fetching token
  let profile = jwtParser(token); //fetching data from jwt token
  let profileName =
    (profile && profile.data && (profile.data.name || profile.data.userName)) || 'User';
  let profileId = (profile && profile.data && profile.data.userId) || '';

  const accessLevelValues = [
    { label: 'All', value: 'all' },
    { label: 'Admins', value: 5 },
    // { label: 'Vendors', value: 8 },
    { label: 'Operations', value: 11 },
    { label: 'Operations: Misc', value: 1 },
    { label: 'Operations: Employment', value: 2 },
    { label: 'Operations: Education', value: 3 },
    { label: 'Operations: Address', value: 4 }
  ];

  const statusValues = [
    { label: 'All', value: 'all' },
    { label: 'Inactive', value: 0 },
    { label: 'Active', value: 1 }
  ];

  const [openCreateModal, setOpenCreateModal] = useState(false);

  const handleClickOpenCreateModal = () => {
    setOpenCreateModal(true);
  };
  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
  };

  //delete modal

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleClickOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const deleteAdmin = () => {
    setDisabledDeleteBtn(true);
    deleteAdminAPI(adminId)
      .then(() => {
        handleCloseDeleteModal();
        toast.info('Admin deleted Successfully');
        props.getAdmins(
          rowsPerPage,
          offset,
          includeCompanyAdmins,
          name,
          email,
          accessLevel,
          status
        );
        setDeleteSuccess(true);
        handleClickOpenDeleteModal(true);
        setDisabledDeleteBtn(false);
      })
      .catch((error) => {
        console.error(error);
        setDisabledDeleteBtn(false);
        errToastMessage(error);
      });
  };

  const changeAccountStatus = (adminId, status) => {
    const admins = props.adminData.rows;
    props
      .updateAdminStatus({ admin_id: adminId, status: !status })
      .then((response) => {
        admins.filter((admin) => admin.id === adminId)[0].status = !admins.filter(
          (admin) => admin.id === adminId
        )[0].status;
        const rowData = getRowData(admins);
        setRows(rowData);
      })
      .catch((error) => {
        errToastMessage(error);
      });
  };

  const handleCloseViewModal = () => {
    setOpenViewModal(false);
  };

  const breadCrumbsArray = [{ label: 'Home', href: `${AppConstants.baseURL}dashboard` }];

  useEffect(() => {
    setLoading(true);
    props.getAdmins(rowsPerPage, offset, includeCompanyAdmins, name, email, accessLevel, status);
  }, []);

  useEffect(() => {
    if (has(props.adminData, 'rows')) {
      let rowsData =
        props.adminData &&
        props.adminData.rows &&
        props.adminData.rows.length &&
        getRowData(props.adminData.rows);
      setLoading(false);
      setRows(rowsData || []);
      setTotalCount(props.adminData.count);
    }
  }, [props.adminData]);

  const getRowData = (values) => {
    let companyRow = [];
    //here data is getting processed
    forEach(values, function (value, key) {
      if (!isEmpty(value)) {
        let rowData = createData(
          key + 1 + offset * rowsPerPage,
          value.id,
          capitalizeName(value.name),
          toLower(value.email),
          value.mobile,
          value.accessLevel ? ADMIN_ACCESS_LEVEL[value.accessLevel] : '',
          value.status ? 'Active' : 'Inactive',
          value.createdAt
            ? moment(value.createdAt).utc('GMT+5:30').format('DD MMM YYYY hh:mm A')
            : '',
          profile.data.accessLevel === 5
            ? profileStatusSwitch(value.id, value.status, value.name)
            : null,
          profile.data.accessLevel === 5 ? buttonInfoEdit(value.id, value.name, value) : null,
          profile.data.accessLevel === 5 ? forceLogoutButton(value.id, value.name) : null
        );
        companyRow.push(rowData);
      }
    });
    return companyRow;
  };

  const forceLogoutAdminOnCLick = (adminId) => {
    setOpenWarningModal(true);
    setLogoutAdminId(adminId);
  };

  //these are filters
  const getNameFilter = () => {
    return (
      <SearchInput
        placeholder={'Search Name'}
        handleSearch={debounce((name) => {
          applyNameFilter(name?.trim());
        }, 500)}
      />
    );
  };

  const getEmailFilter = () => {
    return (
      <SearchInput
        placeholder={'Search Email'}
        handleSearch={debounce((email) => {
          applyEmailFilter(email?.trim());
        }, 500)}
        type='email'
      />
    );
  };
  const getAccessLevelFilter = () => {
    return (
      <Dropdown
        dropdownValues={accessLevelValues}
        label={'Access Level'}
        value={'all'}
        handleSelect={(value) => {
          handleAccessLevelDropdownSelect(value);
        }}
      />
    );
  };

  const getStatusFilter = () => {
    return (
      <Dropdown
        dropdownValues={statusValues}
        label={'Status'}
        value={'all'}
        handleSelect={(value) => {
          handleStatusDropdownSelect(value);
        }}
      />
    );
  };

  const buttonInfoEdit = (adminId, adminName, adminDetails) => {
    return (
      <div className={classes.btnDiv}>
        <Tooltip TransitionComponent={Zoom} title='Edit' arrow={true}>
          <EditOutlinedIcon
            className={classes.editBtn}
            onClick={() => {
              setTitle('Edit');
              setAdminId(adminId);
              setAdminName(adminName);
              setAdminDetails(adminDetails);
              handleClickOpenCreateModal();
            }}
          />
        </Tooltip>
        {profileId !== adminId && (
          <Tooltip TransitionComponent={Zoom} title='Delete' arrow={true}>
            <DeleteOutlineIcon
              className={classes.deleteBtn}
              onClick={() => {
                setAdminId(adminId);
                setAdminName(adminName);
                setAdminDetails(adminDetails);
                setDeleteSuccess(false);
                handleClickOpenDeleteModal();
              }}
            />
          </Tooltip>
        )}
      </div>
    );
  };
  const profileStatusSwitch = (adminId, status, adminName) => {
    return (
      <div>
        {profileId !== adminId && (
          <div className={classes.btnDiv}>
            <Tooltip
              TransitionComponent={Zoom}
              title={status ? 'Enabled' : 'Disabled'}
              arrow={true}
            >
              <Switch
                checked={status}
                onChange={() => changeAccountStatus(adminId, status)}
                color='primary'
                name='checkedB'
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Tooltip>
          </div>
        )}
      </div>
    );
  };
  const forceLogoutButton = (adminId, adminName) => {
    return (
      <div>
        {profileId !== adminId && (
          <div className={classes.btnDiv}>
            <Tooltip TransitionComponent={Zoom} title='Force Logout' arrow={true}>
              <ExitToAppOutlinedIcon
                className={classes.logoutBtn}
                onClick={() => forceLogoutAdminOnCLick(adminId)}
              />
            </Tooltip>
          </div>
        )}
      </div>
    );
  };

  const forceLogoutAdmin = () => {
    const adminData = { admin_id: logoutAdminId };
    props
      .forceLogoutAdmin(adminData)
      .then((response) => {
        toast.success('successfully logged out');
      })
      .catch((error) => {
        errToastMessage(error.response.data.message);
      });
  };

  const applyNameFilter = (n) => {
    setName(n);
    setOffset(0);
    setLoading(true);
    props
      .getAdmins(rowsPerPage, 0, includeCompanyAdmins, n, email, accessLevel, status)
      .then(() => {
        setLoading(false);
      });
  };
  const applyEmailFilter = (e) => {
    setEmail(e);
    setOffset(0);
    setLoading(true);
    props.getAdmins(rowsPerPage, 0, includeCompanyAdmins, name, e, accessLevel, status).then(() => {
      setLoading(false);
    });
  };

  const handleAccessLevelDropdownSelect = (value) => {
    setOffset(0);
    setLoading(true);
    switch (value) {
      case 5:
        setAccessLevel(5);
        props.getAdmins(rowsPerPage, 0, includeCompanyAdmins, name, email, 5, status).then(() => {
          setLoading(false);
        });
        break;
      case 8:
        setAccessLevel(8);
        props.getAdmins(rowsPerPage, 0, includeCompanyAdmins, name, email, 8, status).then(() => {
          setLoading(false);
        });
        break;
      case 11:
        setAccessLevel(11);
        props.getAdmins(rowsPerPage, 0, includeCompanyAdmins, name, email, 11, status).then(() => {
          setLoading(false);
        });
        break;
      case 1:
        setAccessLevel(1);
        props.getAdmins(rowsPerPage, 0, includeCompanyAdmins, name, email, 1, status).then(() => {
          setLoading(false);
        });
        break;
      case 2:
        setAccessLevel(2);
        props.getAdmins(rowsPerPage, 0, includeCompanyAdmins, name, email, 2, status).then(() => {
          setLoading(false);
        });
        break;
      case 3:
        setAccessLevel(3);
        props.getAdmins(rowsPerPage, 0, includeCompanyAdmins, name, email, 3, status).then(() => {
          setLoading(false);
        });
        break;
      case 4:
        setAccessLevel(4);
        props.getAdmins(rowsPerPage, 0, includeCompanyAdmins, name, email, 4, status).then(() => {
          setLoading(false);
        });
        break;

      default:
        setAccessLevel(null);
        props
          .getAdmins(rowsPerPage, 0, includeCompanyAdmins, name, email, null, status)
          .then(() => {
            setLoading(false);
          });
        break;
    }
  };

  const handleStatusDropdownSelect = (value) => {
    setLoading(true);
    setOffset(0);
    switch (value) {
      case 0:
        setStatus(0);
        props
          .getAdmins(rowsPerPage, 0, includeCompanyAdmins, name, email, accessLevel, 0)
          .then(() => {
            setLoading(false);
          });
        break;
      case 1:
        setStatus(1);
        props
          .getAdmins(rowsPerPage, 0, includeCompanyAdmins, name, email, accessLevel, 1)
          .then(() => {
            setLoading(false);
          });
        break;
      default:
        setStatus(null);
        props
          .getAdmins(rowsPerPage, 0, includeCompanyAdmins, name, email, accessLevel, null)
          .then(() => {
            setLoading(false);
          });
        break;
    }
  };

  const handleChangePage = (newPage) => {
    //need to call API with new limit and offset
    setOffset(newPage);
    setLoading(true);
    props
      .getAdmins(rowsPerPage, newPage, includeCompanyAdmins, name, email, accessLevel, status)
      .then(() => {
        setLoading(false);
      });
  };

  const handleChangeRowsPerPage = (value) => {
    //need to call API with new limit and offset
    setRowsPerPage(parseInt(value, 10));
    setOffset(0);
    setLoading(true);
    props
      .getAdmins(parseInt(value, 10), 0, includeCompanyAdmins, name, email, accessLevel, status)
      .then(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <BreadCrumbs linksArray={breadCrumbsArray} current={'Admins'} />
      <Typography variant='h4' component='h3'>
        Admins
      </Typography>
      <Button
        label={'Create Admin'}
        color={'primary'}
        onClick={() => {
          setTitle('Create');
          setAdminId(null);
          setAdminDetails(null);
          setAdminName(null);
          handleClickOpenCreateModal();
        }}
      />
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item lg={3} md={4} sm={4} xs={10}>
            {getNameFilter()}
          </Grid>
          <Grid item lg={3} md={4} sm={4} xs={10}>
            {getEmailFilter()}
          </Grid>
          <Grid item lg={3} md={4} sm={4} xs={10}>
            {getAccessLevelFilter()}
          </Grid>
          <Grid item lg={3} md={4} sm={4} xs={10}>
            {getStatusFilter()}
          </Grid>
        </Grid>
      </Box>
      <Table
        headCells={headCells}
        rowData={rows}
        totalCount={totalCount}
        rowsPerPage={rowsPerPage}
        page={offset}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        loading={loading}
        // filterArr={filterArr}
      />
      <ViewModal
        open={openViewModal}
        handleClose={handleCloseViewModal}
        title={adminName}
        adminDetails={adminDetails}
      />
      {openCreateModal ? (
        <CreateModal
          open={openCreateModal}
          title={title}
          offset={offset}
          rowsPerPage={rowsPerPage}
          details={adminDetails}
          handleClose={handleCloseCreateModal}
          adminId={adminId}
          profile={profileName}
        />
      ) : null}
      <DeletePopup
        title={deleteSuccess ? 'Deleted Successfully' : 'Delete  Admin'}
        content={
          deleteSuccess
            ? 'Admin Deleted Successfully'
            : `Are you sure you want to delete ${adminName}?`
        }
        buttonLabel={deleteSuccess ? 'Ok' : 'Delete'}
        buttonColor={deleteSuccess ? 'primary' : 'secondary'}
        open={openDeleteModal}
        disabled={disabledDeleteBtn}
        handleSubmit={() => {
          deleteSuccess ? handleCloseDeleteModal() : deleteAdmin();
        }}
        handleClose={handleCloseDeleteModal}
      />
      {openWarningModal ? (
        <ConfirmationPopup
          open={openWarningModal}
          title={'Force logout admin?'}
          content={'The admin will be force logged out.'}
          handleSubmit={forceLogoutAdmin}
          handleClose={() => {
            setOpenWarningModal(false);
          }}
          buttonLabel={'Force Logout'}
        />
      ) : null}
    </>
  );
}
