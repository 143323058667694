import React, { useState } from 'react';
import { forEach, isEmpty, has, debounce } from 'lodash';

import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Grid } from '@material-ui/core';

import Table from '../../../core-components/Table';
import AppConstants from '../../../core-components/AppConstants';
import BreadCrumbs from '../../../core-components/BreadCrumbs';
import SearchInput from '../../../core-components/SearchInput';
import { ACCESS_LEVEL, USER_TYPE } from '../../../core-components/CommonConstants';
import { capitalizeName } from '../frontend-common/utils/Utilities';

const useStyles = makeStyles({
  heading: {
    paddingTop: '20px',
    paddingBottom: '20px'
  },
  headingSubText: {
    color: '#8c8c8c',
    fontSize: '18px'
  }
});

function createData(incrementId, tableName, fieldName, id, oldValue, newValue, admin, changedDate) {
  return { incrementId, tableName, fieldName, id, oldValue, newValue, admin, changedDate };
}

const headCells = [
  {
    id: 'incrementId',
    label: '#',
    align: 'left'
  },
  {
    id: 'tableName',
    label: 'Table Name',
    align: 'left'
  },
  {
    id: 'fieldName',
    label: 'Field Name',
    align: 'center'
  },
  {
    id: 'id',
    label: 'ID',
    align: 'center'
  },
  {
    id: 'oldValue',
    label: 'Old value',
    align: 'center'
  },
  {
    id: 'newValue',
    label: 'New Value',
    align: 'center'
  },
  {
    id: 'admin',
    label: 'Admin',
    align: 'center'
  },
  {
    id: 'changedDate',
    label: 'Date of Change',
    align: 'center'
  }
];

export default function CompanyLogs(props) {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [companyUuid, setcompanyUuid] = useState(
    new URLSearchParams(document.location.search).get('company_id') || null
  );
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [companyName, setCompanyName] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [tableName, setTableName] = useState('');

  const breadCrumbsArray = [
    { label: 'Home', href: `${AppConstants.baseURL}dashboard` },
    { label: 'Companies', href: `${AppConstants.baseURL}companies` },
    {
      label: companyName || 'Company Name',
      href: `${AppConstants.baseURL}company/view?id=${companyId}`
    }
  ];

  React.useEffect(() => {
    props.invalidateCompanyLogs();
    getLogs(rowsPerPage, offset);
  }, []);

  React.useEffect(() => {
    if (!isEmpty(props.logs) && has(props.logs, 'loading') && props.logs.loading === false) {
      let rows = props?.logs?.all[offset]?.logs[0]?.result?.length
        ? getRowData(props.logs.all[offset].logs[0].result)
        : [];
      setData(rows);
      setCount(props.logs.all[offset].logs[0]?.count[0]?.count || 0);
      setLoading(props.logs.loading);
      setCompanyId(
        props.logs &&
          props.logs.all[offset] &&
          props.logs.all[offset].company &&
          props.logs.all[offset].company.id
      );
      setCompanyName(
        props.logs &&
          props.logs.all[offset] &&
          props.logs.all[offset].company &&
          props.logs.all[offset].company.name
      );
    }
  }, [props.logs]);

  const getLogs = (rowsPerPage, offset, tableNames) => {
    setLoading(true);
    props
      .getCompanyLogs(companyUuid, rowsPerPage.toString(), offset.toString(), tableNames)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const getRowData = (values) => {
    let logsRow = [];
    forEach(values, function (value, key) {
      if (!isEmpty(value)) {
        let rowData = createData(
          key + 1 + offset * rowsPerPage,
          value.tableName,
          value.fieldName === 'friendly_name' ? 'Brand Name' : value.fieldName,
          value.tableRowId ? value.tableRowId : '',
          value.oldValue
            ? value.fieldName === 'access_level'
              ? ACCESS_LEVEL[value.oldValue]
              : value.oldValue
            : 'NULL',
          value.fieldName === 'deleted_at'
            ? moment(value.newValue).format('DD MMM YYYY hh:mm A')
            : value.fieldName === 'access_level'
            ? ACCESS_LEVEL[value.newValue]
            : value.newValue
            ? value.newValue
            : 'NULL',
          `${value.userType ? USER_TYPE[value.userType] + `:` : ''} ${capitalizeName(
            value.userName
          )} ${value.userId ? `(` + value.userId + `)` : ''}` || null,
          (value.createdAt &&
            moment(value.createdAt).isValid() &&
            moment(value.createdAt).format('DD MMM YYYY hh:mm A')) ||
            null
        );
        logsRow.push(rowData);
      }
    });
    return logsRow;
  };

  const handleChangePage = (newPage) => {
    setOffset(newPage);
    getLogs(rowsPerPage, newPage, tableName);
  };

  const handleChangeRowsPerPage = (value) => {
    setOffset(0);
    setRowsPerPage(parseInt(value, 10));
    getLogs(parseInt(value, 10), 0, tableName);
  };

  const applyTableNameFilter = (name) => {
    setTableName(name);
    setOffset(0);
    getLogs(rowsPerPage, 0, name);
  };

  const getTableNameFilter = () => {
    return (
      <SearchInput
        placeholder={'Search by Table name'}
        handleSearch={debounce((name) => {
          applyTableNameFilter(name?.trim());
        }, 500)}
      />
    );
  };

  return (
    <>
      <BreadCrumbs linksArray={breadCrumbsArray} current={'logs'} />
      <div className={classes.heading}>
        <Typography variant='h5' component='h5'>
          {'Logs'}
        </Typography>
        <Typography className={classes.headingSubText} variant='caption' component='h6'>
          {companyName || 'Company Name'}
        </Typography>
      </div>
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item lg={3} md={4} sm={4} xs={10}>
            {getTableNameFilter()}
          </Grid>
        </Grid>
      </Box>
      <Table
        headCells={headCells}
        rowData={data}
        totalCount={count}
        rowsPerPage={rowsPerPage}
        page={offset}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        loading={loading || props.logs.loading}
      />
    </>
  );
}
