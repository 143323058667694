import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, isEmpty, toLower } from 'lodash';

import { candidateExistanceCheck as existanceAPI } from '../../../../api/admin';
import { getIntlPhoneNumberObject } from '../../../../utils/Utlities';
import validationSchema from './BasicDetails.validation';
import {
  getAddCandidateConfig as getAddCandidateConfigAPI,
  invalidateAddCandidateConfig as invalidateAddCandidateConfigAPI
} from '../../../../actions/admin';
import { capitalizeName } from '../../frontend-common/utils/Utilities';

const mapStateToProps = (state) => {
  return {
    addCandidateConfig:
      state.addCandidateConfig && !isEmpty(state.addCandidateConfig)
        ? state.addCandidateConfig
        : null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    invalidateAddCandidateConfig: () => dispatch(invalidateAddCandidateConfigAPI()),
    getAddCandidateConfig: (companyId) => dispatch(getAddCandidateConfigAPI(companyId))
  };
};

const formatObject = (formData) => {
  let finalData = { ...formData };
  finalData.isConsentUndertakingLetter = formData.isConsentUndertakingLetter === 2 ? true : false;

  finalData.phone = formData?.phone ? formData?.countryCode + formData?.phone : formData?.phone;
  finalData.altPhone = formData?.altPhone
    ? formData?.altCountryCode + formData?.altPhone
    : formData?.altPhone;
  finalData.employeeId = formData.employeeId ? formData.employeeId : '';

  if (finalData.resumeLink) {
    finalData['resume'] = finalData.resumeLink;
  } else {
    delete finalData['resume'];
  }

  if (finalData.consentLink) {
    finalData['consent'] = finalData.consentLink;
  } else {
    delete finalData['consent'];
  }

  if (finalData.isConsentCanBeAdded) {
    delete finalData['consent'];
    delete finalData['consentLink'];
  }

  if (finalData.isConsentUndertakingLetter) {
    finalData['isConsentCanBeAdded'] = undefined;
  }

  if (finalData?.hrInviteNote?.trim()) {
    finalData['hrInviteNote'] = finalData?.hrInviteNote?.trim();
  } else {
    finalData['hrInviteNote'] = undefined;
  }

  return finalData;
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        companyId: '',
        name: '',
        email: '',
        emailExists: false,
        alternateEmail: '',
        countryCode: '+91',
        phone: '',
        phoneExists: false,
        isValidPhone: true,
        isAltValidPhone: true,
        employeeIdExists: false,
        altCountryCode: '+91',
        altPhone: '',
        employeeId: '',
        uanNumber: '',
        tags: [],
        resume: '',
        resumeLink: '',
        consent: '',
        consentLink: '',
        invite: 'true',
        resumeName: '',
        consentName: '',
        isConsentCanBeAdded: false,
        isConsentUndertakingLetter:
          props?.addCandidateConfig &&
          props?.addCandidateConfig?.consentType === 'UNDERTAKING_LETTER'
            ? 2
            : 1,
        formConfig: {
          isEmailOptional: props.addCandidateConfig
            ? props?.addCandidateConfig.isEmailOptional === 1
              ? true
              : false
            : false,
          isResumeOptional: props.addCandidateConfig
            ? props?.addCandidateConfig?.isResumeOptional === 1
              ? true
              : false
            : false
        },
        category: '',
        companyName: '',
        hrInviteNote: ''
      };
      let propsValue = {},
        companyId = new URLSearchParams(document.location.search).get('company_id');

      propsValue = {
        companyId: companyId,
        companyName: props?.addCandidateConfig?.companyName,
        hrInviteNote: props?.addCandidateConfig?.hrInviteNoteStatus
          ? props?.addCandidateConfig?.hrInviteNote
          : '',
        name: capitalizeName(props?.name),
        email: toLower(props?.email) || '',
        alternateEmail: toLower(props?.alternateEmail) || ''
      };

      if (props?.savedValues && !isEmpty(props.savedValues)) {
        propsValue = { ...propsValue, ...props.savedValues };
        propsValue = {
          ...propsValue,
          isConsentUndertakingLetter:
            props?.savedValues?.isConsentUndertakingLetter === true ? 2 : 1
        };
        if (propsValue?.phone) {
          let phoneNumberObj = getIntlPhoneNumberObject(propsValue.phone);
          if (phoneNumberObj) {
            propsValue.phone = phoneNumberObj.nationalNumber;
            propsValue.countryCode = `+${phoneNumberObj.countryCallingCode}`;
          }
        }
        if (propsValue?.altPhone) {
          let phoneNumberObj = getIntlPhoneNumberObject(propsValue.altPhone);
          if (phoneNumberObj) {
            propsValue.altPhone = phoneNumberObj.nationalNumber;
            propsValue.altCountryCode = `+${phoneNumberObj.countryCallingCode}`;
          }
        }
      }

      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    handleSubmit: async (values, { props, setFieldValue }) => {
      values.email = values.email?.toLowerCase();
      values.alternateEmail = values.alternateEmail?.toLowerCase();

      const finalValues = formatObject(values, values.isEdit);
      let existEmailAsyncResponse = null;
      let existPhoneAsyncResponse = null;
      let requestEmail = {
        companyId: values.companyId,
        params: {
          email: values.email
        }
      };

      let requestPhone = {
        companyId: values.companyId,
        params: {
          phone: values.countryCode + values.phone
        }
      };
      try {
        existEmailAsyncResponse = await existanceAPI(requestEmail);
        if (existEmailAsyncResponse.data.isExist) {
          setFieldValue('emailExists', true);
        } else {
          setFieldValue('emailExists', false);
        }
      } catch (error) {
        console.error(error);
      }
      try {
        existPhoneAsyncResponse = await existanceAPI(requestPhone);
        if (existPhoneAsyncResponse.data.isExist) {
          setFieldValue('phoneExists', true);
        } else {
          setFieldValue('phoneExists', false);
        }
      } catch (error) {
        console.error(error);
      }
      if (!existEmailAsyncResponse?.data?.isExist && !existPhoneAsyncResponse?.data?.isExist) {
        props.handleStepSubmit(['step1'], [finalValues], 2);
      }
    },
    displayName: 'Create Candidate'
  })
);
