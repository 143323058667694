import React, { useEffect, useState } from 'react';
import { isEmpty, toLower } from 'lodash';
import { Field } from 'formik';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Paper,
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  FormHelperText,
  Checkbox,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Input,
  Chip,
  TextField
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

import FormInput from '../../../core-components/FormInput';
import FileInput from '../../../core-components/FileInput';
import BreadCrumbs from '../../../core-components/BreadCrumbs';
import { candidateExistanceCheck as existanceAPI } from '../../../api/admin';
import AppConstants from '../../../core-components/AppConstants';
import CustomValidatedSelect from '../../../core-components/CustomValidatedSelect';
import {
  getTrimmedValue,
  validResumeTypes,
  isValidPhoneNumber,
  validDocTypes,
  validateCategoriesAndTags
} from '../../../utils/Utlities';
import countryNames from '../../../utils/countryNamesEn.json';
import countryOptions from '../../../utils/countryCodesEn.json';
import { UPLOAD_FILE_TYPES } from '../../../core-components/CommonConstants';
import { Autocomplete } from '@material-ui/lab';
import { capitalizeName } from '../frontend-common/utils/Utilities';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex'
  },
  paper: {
    padding: theme.spacing(4),
    margin: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  formControl: {
    marginBottom: theme.spacing(3)
  },
  activeBtn: {
    margin: theme.spacing(1),
    height: '44px',
    color: '#ffffff',
    borderColor: '#388e3c',
    backgroundColor: '#43a047',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  disabledBtn: {
    cursor: 'not-allowed',
    margin: theme.spacing(1),
    height: '44px',
    color: '#dfdfdf',
    borderColor: '#d9d9d9',
    backgroundColor: '#f5f5f5',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  errorMsg: {
    fontSize: '0.75rem',
    color: '#f44336',
    fontWeight: '400',
    lineHeight: '1.66',
    margin: 0,
    marginTop: theme.spacing(-4),
    marginBottom: theme.spacing(3)
  },
  required: {
    color: '#db3131'
  },
  inviteActiveBtn: {
    margin: theme.spacing(1),
    height: '44px',
    fontSize: 14,
    lineHeight: 1,
    marginRight: 2,
    textTransform: 'inherit'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  error: {
    fontSize: '14px',
    color: '#f44336',
    margin: 0,
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(2)
  },
  typographyStyles: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: '4px'
  },
  phoneErrorMsg: {
    fontSize: '12px',
    color: '#f44336',
    marginBottom: '1rem'
  }
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250
    }
  }
};

function getStyles(value, tags, theme) {
  return {
    fontWeight:
      tags.indexOf(value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  };
}

export default (props) => {
  const {
    handleSubmit,
    isSubmitting,
    handleBlur,
    values,
    errors,
    setFieldValue,
    setFieldError,
    setTouched,
    touched,
    setFieldTouched,
    setErrors,
    addCandidateConfig,
    setSubmitting,
    getAddCandidateConfig,
    invalidateAddCandidateConfig
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [companyServices, setCompanyServices] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [isUploading, setIsUploading] = useState(null);
  const [isConsentUndertakingLetter, setIsConsentUndertakingLetter] = useState(true);
  const [isConsentUploading, setIsConsentUploading] = useState(null);
  const [isCategoriesLoading, setCategoriesLoading] = useState(false);
  const [isTagsLoading, setTagsLoading] = useState(false);
  const [deletedTags, setDeletedTags] = useState([]);
  const [deletedCategoryId, setDeletedCategoryId] = useState(null);
  const [deletedCategoryError, setDeletedCategoryError] = useState(null);
  const [deletedTagsError, setDeletedTagsError] = useState(null);
  const [categoriesList, setCategoriesList] = useState([]);
  const [tagsList, setTagsList] = useState([]);

  useEffect(() => {
    setCategoriesList(addCandidateConfig?.categories || []);
    setTagsList(addCandidateConfig?.tags || []);
  }, [addCandidateConfig]);

  const breadCrumbsArray = [
    { label: 'Home', href: `${AppConstants.baseURL}dashboard` },
    // { label: 'Companies', href: `${AppConstants.baseURL}company/index` },
    { label: 'Companies', href: `${AppConstants.baseURL}companies` },
    {
      label: companyName || 'Company Name',
      href: `${AppConstants.baseURL}company/view?id=${companyId}`
    },
    {
      label: 'Candidates',
      href: `${AppConstants.baseURL}company/candidates?id=${props.values.companyId}`
    }
  ];

  useEffect(() => {
    if (props.values.companyId) {
      props.getCompanyServices(props.values.companyId);

      props.invalidateCompanyBgvConfig();
      props.getCompanyBgvConfig(props.values.companyId);
      invalidateAddCandidateConfig();
      getAddCandidateConfig(props.values.companyId);
    }

    if (props.values.isEdit && props.values.companyId && props.values.candidateId) {
      props.invalidateViewCandidates();
      props.getViewCandidate(props.values.candidateId, props.values.companyId);
    }

    return () => {
      props.invalidateCompanyBgvConfig();
    };
  }, []);

  useEffect(() => {
    let selectedServices = [];
    if (props?.companyServices?.length) {
      for (let index = 0; index < props.companyServices.length; index++) {
        let item = props.companyServices[index];
        if (parseInt(item.defaultService) === 1) {
          selectedServices.push(item.service.id);
        }
      }
      props.setFieldValue('services', selectedServices);
    }
    setCompanyServices(props.companyServices);

    if (props?.companyData) {
      setCompanyId(props.companyData.id || null);
      setCompanyName(props.companyData.name || null);
    }
  }, [props.companyServices]);

  useEffect(() => {
    if (props?.companyBgvConfig && !props?.companyBgvConfig?.loading) {
      setFieldValue('formConfig', {
        isEmailOptional: props?.companyBgvConfig?.isEmailOptional ? false : true
      });
    } else {
      setFieldValue('formConfig', {});
    }
  }, [props.companyBgvConfig]);

  const handleServiceChange = (event) => {
    let dummyServices = (props?.values?.services?.length && [...props?.values?.services]) || [];
    const value = parseInt(event.target.value);
    if (props?.values?.services.includes(value)) {
      dummyServices = props?.values?.services.filter((item) => {
        return item !== value;
      });
      props.setFieldValue('services', [...dummyServices]);
    } else {
      props.setFieldValue('services', [...dummyServices, value]);
    }
  };

  useEffect(() => {
    if (values.email) {
      setIsConsentUndertakingLetter(true);
      setFieldValue('consent', '');
    } else {
      setIsConsentUndertakingLetter(false);
    }
  }, [values.email]);

  useEffect(() => {
    var fileName = values.resumeName;
    if (fileName !== '' && validResumeTypes(fileName)) {
      setFieldValue('validResumeFormat', true);
    } else {
      setFieldValue('validResumeFormat', false);
    }
  }, [values.resumeName]);

  const setValue = (fieldname, value, withSpace) => {
    if (value) {
      const trimmedVal = getTrimmedValue(value, withSpace);
      setFieldValue(fieldname, trimmedVal);
      return trimmedVal;
    } else {
      setFieldValue(fieldname, '');
    }
  };

  const isValidForm =
    !isSubmitting &&
    isEmpty(errors) &&
    !isUploading &&
    !deletedCategoryError &&
    !deletedTagsError &&
    values.isValidPhone &&
    (values?.isAltValidPhone ? true : values?.isAltValidPhone) &&
    ((!values.email && values.consent) ||
      (!props.companyBgvConfig?.isEmailOptional ? values.email : true)) &&
    (!values.isEdit
      ? !props.companyBgvConfig?.isResumeOptional
        ? values?.validResumeFormat
        : true
      : true) &&
    (!values.isEdit ? (values?.resumeLink ? values?.validResumeFormat : true) : true) &&
    (values.isEdit ? (values.resumeName ? values?.validResumeFormat : true) : true);

  const handleResumeValidation = (isValid) => {
    setFieldValue('resume', '');
    setFieldValue('resumeLink', undefined);
    setFieldValue('validResumeFormat', isValid);
  };

  const getConfigData = async () => {
    try {
      const config = await getAddCandidateConfig(companyId);
      return config;
    } catch (err) {
      console.error('err: ', err);
    }
  };

  const onSaveCandidate = async () => {
    const selectedCategoryId = values?.category?.id;
    const selectedTags = values?.tags;
    if (!selectedCategoryId && (!selectedTags || selectedTags.length === 0)) {
      handleSubmit();
      return;
    }
    try {
      setSubmitting(true);
      const config = await getAddCandidateConfig(companyId);
      const categoriesList = config?.categories;
      const tags = config?.tags;
      const res = validateCategoriesAndTags(values?.category, values.tags, categoriesList, tags);
      if (isEmpty(res)) {
        handleSubmit();
        return;
      }
      if (res?.tagsError) {
        setDeletedTags(res?.deletedTags || []);
        setDeletedTagsError(res.tagsError);
      }
      if (res?.categoryError) {
        setDeletedCategoryId(res.deletedCategoryId);
        setDeletedCategoryError(res.categoryError);
      }
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
      console.error('handleContinue Error: ', err);
    }
  };

  const validateCategoryOnChange = (value) => {
    if (!value || (value && value !== deletedCategoryId)) {
      setDeletedCategoryError(null);
    }
  };

  const validateTagsOnChange = (value) => {
    if (!value || deletedTags.length <= 0) {
      setDeletedTagsError(null);
      return;
    }
    if (value && deletedTags.length > 0) {
      const selectedIds = value.map((t) => t.id);
      const res = deletedTags.filter((dt) => selectedIds.some((st) => dt.id === st));
      if (res.length <= 0) setDeletedTagsError(null);
    }
  };

  const onOpenCategoryMenu = async () => {
    setCategoriesLoading(true);
    await getConfigData();
    setCategoriesLoading(false);
  };

  const onOpenTagsMenu = async () => {
    setTagsLoading(true);
    await getConfigData();
    setTagsLoading(false);
  };

  return (
    <>
      <BreadCrumbs
        linksArray={breadCrumbsArray}
        current={values.isEdit ? 'Edit Candidate' : 'Create Candidate '}
      />
      <Grid className={classes.root} container direction={'row'}>
        <Grid item xl={1} md={1} lg={1} sm={false} xs={false}></Grid>
        <Grid item xl={10} md={10} lg={10} sm={12} xs={12}>
          <Paper className={classes.paper} variant='outlined' elevation={3}>
            <Typography variant='h4' gutterBottom>
              {values.isEdit ? 'Edit ' : 'Create '} Candidate
            </Typography>
            <form onSubmit={handleSubmit}>
              <Field
                type='text'
                name='name'
                requiredfield={1}
                label='Name'
                component={FormInput}
                onBlur={(e) => {
                  props.handleBlur(e);
                  setValue('name', capitalizeName(values.name), true);
                }}
              />
              <Field
                type='text'
                name='email'
                label='Email'
                requiredfield={props.companyBgvConfig?.isEmailOptional ? 0 : 1}
                onBlur={(e) => {
                  props.handleBlur(e);
                  setValue('email', toLower(values.email), false);
                  setFieldValue('emailExists', false);
                  if (values.email && !errors.email && !values.isEdit) {
                    let request = {
                      companyId: values.companyId,
                      params: {
                        email: values.email
                      }
                    };
                    existanceAPI(request)
                      .then((response) => {
                        if (response.data.isExist) {
                          setFieldValue('emailExists', true);
                        } else {
                          setFieldValue('emailExists', false);
                        }
                      })
                      .catch((error) => {
                        console.error(error);
                        setFieldValue('emailExists', false);
                      });
                  }
                }}
                component={FormInput}
              />
              <Field
                type='text'
                name='alternateEmail'
                label='Alt. Email'
                component={FormInput}
                onBlur={(e) => {
                  props.handleBlur(e);
                  setValue('alternateEmail', toLower(values.alternateEmail), false);
                }}
              />
              <Typography component='legend' className={classes.typographyStyles}>
                Mobile Number <span className={classes.required}>*</span>
              </Typography>
              <Grid container spacing={2} className={classes.marginB}>
                <Grid item xl={2} md={2} lg={2} sm={2} xs={12}>
                  <Field
                    type='text'
                    name='countryCode'
                    defaultValue={values.countryCode}
                    options={countryNames}
                    filterLabel={countryOptions}
                    component={CustomValidatedSelect}
                    onChange={(e) => {
                      setFieldValue('isValidPhone', true);
                      if (values.phone && e) {
                        let intlPhoneNumber = `${e + '' + values.phone}`;
                        if (!isValidPhoneNumber(intlPhoneNumber)) {
                          setFieldValue('isValidPhone', false);
                        } else {
                          setFieldValue('isValidPhone', true);
                        }
                      }
                      if (values.phone && e) {
                        let intlPhoneNumber = `${e + '' + values.phone}`;
                        let request = {
                          companyId: values.companyId,
                          params: {
                            phone: intlPhoneNumber
                          }
                        };
                        existanceAPI(request)
                          .then((response) => {
                            if (response.data.isExist) {
                              setFieldValue('phoneExists', true);
                            } else {
                              setFieldValue('phoneExists', false);
                            }
                          })
                          .catch((error) => {
                            console.error(error);
                            setFieldValue('phoneExists', false);
                          });
                      }
                    }}
                  />
                </Grid>
                <Grid item xl={10} md={10} lg={10} sm={10} xs={12}>
                  <Field
                    type='text'
                    name='phone'
                    inputProps={{ 'data-testid': 'phone-field' }}
                    variant='outlined'
                    component={FormInput}
                    fullWidth
                    onBlur={(e) => {
                      props.handleBlur(e);
                      const trimmedVal = setValue('phone', values.phone, false);
                      setFieldValue('phoneExists', false);
                      setFieldValue('isValidPhone', true);
                      if (trimmedVal && values.countryCode) {
                        let intlPhoneNumber = `${values.countryCode + '' + trimmedVal}`;
                        if (!isValidPhoneNumber(intlPhoneNumber)) {
                          setFieldValue('isValidPhone', false);
                        } else {
                          setFieldValue('isValidPhone', true);
                        }
                      }
                      if (trimmedVal && values.countryCode && !values.isEdit) {
                        let intlPhoneNumber = `${values.countryCode + '' + trimmedVal}`;
                        let request = {
                          companyId: values.companyId,
                          params: {
                            phone: intlPhoneNumber
                          }
                        };
                        existanceAPI(request)
                          .then((response) => {
                            if (response.data.isExist) {
                              setFieldValue('phoneExists', true);
                            } else {
                              setFieldValue('phoneExists', false);
                            }
                          })
                          .catch((error) => {
                            console.error(error);
                            setFieldValue('phoneExists', false);
                          });
                      }
                    }}
                  />
                  {!errors.phone && !values.isValidPhone && (
                    <div className={classes.phoneErrorMsg}>
                      {!values.isValidPhone ? 'Please add a valid Phone Number' : ''}
                    </div>
                  )}
                </Grid>
              </Grid>
              <Typography component='legend' className={classes.typographyStyles}>
                Alt. Mobile Number
              </Typography>
              <Grid container spacing={2}>
                <Grid item xl={2} md={2} lg={2} sm={2} xs={12}>
                  <Field
                    type='text'
                    name='altCountryCode'
                    defaultValue={values.altCountryCode}
                    options={countryNames}
                    filterLabel={countryOptions}
                    component={CustomValidatedSelect}
                    onChange={(e) => {
                      setFieldValue('isAltValidPhone', true);
                      if (values.altPhone && e) {
                        let intlPhoneNumber = `${e + '' + values.altPhone}`;
                        if (!isValidPhoneNumber(intlPhoneNumber)) {
                          setFieldValue('isAltValidPhone', false);
                        } else {
                          setFieldValue('isAltValidPhone', true);
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xl={10} md={10} lg={10} sm={10} xs={12}>
                  <Field
                    type='text'
                    name='altPhone'
                    inputProps={{ 'data-testid': 'alt-phone-field' }}
                    component={FormInput}
                    variant='outlined'
                    fullWidth
                    onBlur={(e) => {
                      props.handleBlur(e);
                      const trimmedVal = setValue('altPhone', values.altPhone, false);
                      setFieldValue('isAltValidPhone', true);
                      if (trimmedVal && values.altCountryCode) {
                        let intlPhoneNumber = `${values.altCountryCode + '' + trimmedVal}`;
                        if (!isValidPhoneNumber(intlPhoneNumber)) {
                          setFieldValue('isAltValidPhone', false);
                        } else {
                          setFieldValue('isAltValidPhone', true);
                        }
                      }
                    }}
                  />
                  {!values.isAltValidPhone && (
                    <div className={classes.phoneErrorMsg}>
                      {!values.isAltValidPhone ? 'Please add a valid Phone Number' : ''}
                    </div>
                  )}
                </Grid>
              </Grid>
              <Field
                type='text'
                name='employeeId'
                label='Employee ID'
                component={FormInput}
                onBlur={(e) => {
                  props.handleBlur(e);
                  setValue('employeeId', values.employeeId, true);
                }}
              />
              <FormControl component='fieldset' fullWidth className={classes.formControl}>
                <FormLabel component='legend'>Sex</FormLabel>
                <RadioGroup
                  row
                  aria-label='gender'
                  name='gender'
                  value={values.gender}
                  onChange={(e) => {
                    setFieldValue('gender', parseInt(e.target.value));
                  }}
                >
                  <FormControlLabel value={1} control={<Radio color='primary' />} label='Male' />
                  <FormControlLabel value={2} control={<Radio color='primary' />} label='Female' />
                  <FormControlLabel
                    value={3}
                    control={<Radio color='primary' />}
                    label='Non-binary'
                  />
                  <FormControlLabel
                    value={4}
                    control={<Radio color='primary' />}
                    label='Prefer not to disclose'
                  />
                </RadioGroup>
              </FormControl>
              <Field
                type='text'
                name='uanNumber'
                label='Universal Account Number'
                component={FormInput}
                onBlur={(e) => {
                  props.handleBlur(e);
                  setValue('uanNumber', values.uanNumber, false);
                }}
              />
              <FormControl>
                <FormLabel component='legend' className={classes.marginB}>
                  Upload Resume{' '}
                  {props.companyBgvConfig?.isResumeOptional ? null : (
                    <span className={classes.required}>*</span>
                  )}
                </FormLabel>

                <Field
                  name='resume'
                  component={FileInput}
                  candidateId={values.isEdit ? values.candidateId : null}
                  setIsUploading={setIsUploading}
                  fileName={values.resumeName || ''}
                  nameField={'resumeName'}
                  uploadToServer={'s3Upload'}
                  linkFieldName={'resumeLink'}
                  defaultField={false}
                  accept={validDocTypes.resume}
                  setValidation={handleResumeValidation}
                  uploadFileType={UPLOAD_FILE_TYPES.RESUME}
                />
                {!values.validResumeFormat && values.resumeName !== '' && (
                  <div className={classes.error}>
                    Please provide a valid resume format(.pdf,.doc,.docx)
                  </div>
                )}
              </FormControl>
              {categoriesList?.length && (
                <FormControl fullWidth className={classes.formControl}>
                  <Autocomplete
                    options={
                      isCategoriesLoading
                        ? [{ id: '_disabled', categoryName: 'Loading...' }]
                        : categoriesList
                    }
                    getOptionLabel={(option) => option.categoryName || ''}
                    value={values.category}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionDisabled={(option) => option.id === '_disabled'}
                    loading={isCategoriesLoading}
                    onOpen={onOpenCategoryMenu}
                    onChange={(event, newValue) => {
                      setFieldValue('category', newValue);
                      validateCategoryOnChange(newValue?.id);
                    }}
                    renderInput={(params) => <TextField {...params} label='Category' />}
                  />
                  {deletedCategoryError && (
                    <div
                      className={classes.phoneErrorMsg}
                      dangerouslySetInnerHTML={{ __html: deletedCategoryError }}
                    ></div>
                  )}
                </FormControl>
              )}

              {tagsList?.length && (
                <FormControl fullWidth className={classes.formControl}>
                  <Autocomplete
                    options={
                      isTagsLoading ? [{ id: '_disabled', category: 'Loading...' }] : tagsList
                    }
                    multiple
                    getOptionLabel={(option) => option.category || ''}
                    value={values.tags}
                    loading={isTagsLoading}
                    disableCloseOnSelect
                    onOpen={onOpenTagsMenu}
                    getOptionDisabled={(option) => option.id === '_disabled'}
                    onChange={(event, newValue) => {
                      setFieldValue('tags', newValue);
                      validateTagsOnChange(newValue);
                    }}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderInput={(params) => <TextField {...params} label='Tags' />}
                  />
                  {deletedTagsError && (
                    <div
                      className={classes.phoneErrorMsg}
                      dangerouslySetInnerHTML={{ __html: deletedTagsError }}
                    ></div>
                  )}
                </FormControl>
              )}

              {/* Consent */}
              {!values.isEdit ? (
                <Box mb={5} mt={2}>
                  <Grid container spacing={2}>
                    <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                      <Typography variant='subtitle2'>Consent</Typography>
                    </Grid>
                    <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!values.email}
                            checked={isConsentUndertakingLetter}
                            onChange={(e) => {
                              setIsConsentUndertakingLetter(e.target.checked);
                            }}
                            name='consentCheck'
                            color='primary'
                          />
                        }
                        label={
                          <>
                            <Typography variant='subtitle1'>Ask candidate for consent</Typography>
                            <Typography variant='caption' display='block'>
                              (You cannot ask candidate for consent as you have not added an email
                              address)
                            </Typography>
                          </>
                        }
                      />
                    </Grid>

                    {!isConsentUndertakingLetter ||
                    (!values.email && isConsentUndertakingLetter) ? (
                      <Grid item container spacing={2} xl={12} md={12} lg={12} sm={12} xs={12}>
                        <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                          <Typography variant='subtitle1' display='block'>
                            Upload Manual Consent Document
                          </Typography>
                        </Grid>
                        <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                          <Field name='consent' component={FileInput} defaultField={false} />
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                </Box>
              ) : null}

              {!values.isEdit ? (
                <Grid item container xl={12} md={12} lg={12} sm={12} xs={12}>
                  <Grid item xl={5} md={5} lg={5} sm={5} xs={5}>
                    <FormControl
                      error={values.services.length > 0 && errors.services}
                      component='fieldset'
                      className={classes.formControl}
                    >
                      <FormLabel>Services</FormLabel>
                      <FormGroup>
                        {companyServices?.length
                          ? companyServices.map((item, index) => {
                              return (
                                <FormControlLabel
                                  key={`checkbox_${item.companyServiceName}_${index}`}
                                  control={
                                    <Checkbox
                                      color='primary'
                                      onChange={handleServiceChange}
                                      checked={values.services.includes(item.serviceId)}
                                      name={`checkbox_${item.companyServiceName}_${index}`}
                                      value={item.serviceId}
                                    />
                                  }
                                  label={item.companyServiceName}
                                />
                              );
                            })
                          : 'No services added'}
                      </FormGroup>
                      {values.services.length > 0 && (
                        <FormHelperText>{errors.services}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {companyServices?.length &&
                  companyServices[0]?.identities &&
                  companyServices[0]?.identities?.length ? (
                    <Grid item xl={2} md={2} lg={2} sm={2} xs={2}>
                      <FormControl className={classes.formControl} style={{ width: '100%' }}>
                        <InputLabel id='id-type'>Identity </InputLabel>
                        <Select
                          labelId='id-type-label'
                          value={values.selectedIdentity}
                          onChange={(e) => {
                            setFieldValue('selectedIdentity', e.target.value);
                          }}
                          disabled={companyServices[0].identities.length === 1}
                          displayEmpty
                          fullWidth
                        >
                          {companyServices[0].identities.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item.id}>
                                {item.identityName}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : null}
                </Grid>
              ) : null}

              {values.isEdit ? (
                <Button
                  variant='contained'
                  size='large'
                  type='button'
                  onClick={onSaveCandidate}
                  className={isValidForm ? classes.activeBtn : classes.disabledBtn}
                  disabled={!isValidForm}
                  startIcon={<SaveIcon />}
                >
                  Save
                </Button>
              ) : (
                <>
                  <Button
                    variant='contained'
                    color={'primary'}
                    size='large'
                    type='submit'
                    className={isValidForm ? classes.inviteActiveBtn : classes.disabledBtn}
                    disabled={!isValidForm}
                    startIcon={<EventAvailableIcon />}
                    onClick={() => {
                      setFieldValue('invite', true);
                      // values.invite = true;
                    }}
                  >
                    Invite candidate to enter details
                  </Button>
                  <Button
                    variant='contained'
                    size='large'
                    type='submit'
                    className={isValidForm ? classes.activeBtn : classes.disabledBtn}
                    disabled={!isValidForm}
                    startIcon={<SaveIcon />}
                  >
                    Enter candidate details yourself
                  </Button>
                </>
              )}
            </form>
          </Paper>
        </Grid>
        <Grid item xl={1} md={1} lg={1} sm={false} xs={false}></Grid>
      </Grid>
    </>
  );
};
